import React from 'react';
import Layout from 'layout';
import styled from 'styled-components';
import { media } from 'theme/breakpoints';
import { data as usabilityTestingData } from 'data/guides/usability-testing';
import { data as userExperienceData } from 'data/guides/user-experience';
import { data as heatmapsData } from 'data/guides/heatmaps';
import StartUsing from 'components/ui/startUsing';
import guidesUsability from 'img/usability-testing/cover/usabilityTesting.svg';
import guidesUsabilityMobile from 'img/usability-testing/cover/usabilityTesting1.svg';
import guidesUser from 'img/user-experience/cover/userexperience.svg';
import guidesUserMobile from 'img/user-experience/cover/userexperience1.png';
import heatmaps from 'img/heatmaps/cover/heatmaps.png';
import heatmapsMobile from 'img/heatmaps/cover/heatmaps1.png';
import CustomLink from 'components/ui/link';

const Section = styled.section`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 40px;
  align-items: center;
  padding-bottom: 50px;
  padding-top: 88px;
  & img {
    border-radius: 4px;
  }
  & picture {
    grid-area: ${(props) => (props.secondary ? '1 / 3 / 2 / 2' : 'auto')};
  }
  ${media.desktopWide} {
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(1, min-content);
    grid-row-gap: 80px;
    & img {
      width: 100%;
    }
    & picture {
      grid-area: auto;
    }
  }
  ${media.mobile} {
    padding-top: 40px;
    grid-row-gap: 40px;
  }
`;

const Header = styled.section`
  margin-top: 5rem;
  text-align: center;
  padding-bottom: 0;
  ${media.desktopWide} {
    margin-top: 3rem;
  }
  ${media.tablet} {
    & h1.line {
      & span {
        &::after {
          display: none;
        }
      }
    }
  }
  ${media.mobile} {
    margin-top: 1rem;
  }
`;

const SubTitle = styled.p`
  font-size: 22px;
  line-height: 34px;
  margin-top: 32px;
  max-width: 85%;
  margin: 32px auto 0;
  ${media.mobile} {
    margin-top: 24px;
    font-size: 20px;
    line-height: 30px;
    max-width: 100%;
  }
`;

const Chapters = styled.div`
  & h2 {
    margin-bottom: 24px;
  }
  & .arrow-paragraph {
    margin-bottom: 32px;
  }
  & ul {
    list-style: none;
    padding: 32px 0 0;
    margin: 0;
    border-top: 1px solid #e4e5e8;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(4, auto);
    grid-column-gap: 28px;
    grid-row-gap: 24px;
    ${media.desktopWide} {
      grid-column-gap: 46px;
    }
    ${media.tablet} {
      grid-gap: 24px;
    }
    & li {
      & a {
        color: #000;
        &:hover {
          color: #000;
          text-decoration: none;
          & p {
            text-decoration: underline;
          }
        }
        & span {
          font-size: 12px;
          line-height: 20px;
          color: #808080;
          margin-bottom: 6px;
          text-transform: uppercase;
        }
        & p {
          font-size: 16px;
          line-height: 26px;
          margin-bottom: 0;
          color: #000;
        }
      }
    }
  }
`;

const Guides = () => {
  return (
    <Layout
      metaTitle="Guides | Grow Your Business"
      metaDescription="Learn and grow with LiveSession. Read our guides to usability testing, user experience, qualitative analytics and more."
      canonical="/resources/guides/"
    >
      <div className="container">
        <Header>
          <h1 className="line">
            LiveSession guides to <span>grow your business</span>
          </h1>
          <SubTitle>
            We have many years of experience in business development from many areas, so we decided
            to share our knowledge to help your company grow.
          </SubTitle>
        </Header>
        <Section>
          <picture>
            <source media="(max-width:1200px)" srcSet={guidesUsabilityMobile} />
            <img
              src={guidesUsability}
              alt="Guides cover"
              title="Usability Testing"
              className="img-fluid"
            />
          </picture>
          <Chapters>
            <h2>Usability testing</h2>
            <CustomLink href="/usability-testing/" className="arrow-paragraph" withArrow>
              Read the guide
            </CustomLink>
            <ul>
              {usabilityTestingData.map(({ desc, title, url }) => (
                <li key={url}>
                  <CustomLink href={url}>
                    <span>{title}</span>
                    <p>{desc}</p>
                  </CustomLink>
                </li>
              ))}
            </ul>
          </Chapters>
        </Section>
        <Section secondary>
          <picture>
            <source media="(max-width:1200px)" srcSet={guidesUserMobile} />
            <img
              src={guidesUser}
              alt="Guides cover"
              title="User Experience"
              className="img-fluid"
            />
          </picture>
          <Chapters>
            <h2>User experience</h2>
            <CustomLink href="/user-experience/" className="arrow-paragraph" withArrow>
              Read the guide
            </CustomLink>
            <ul>
              {userExperienceData.map(({ desc, title, url }) => (
                <li key={url}>
                  <CustomLink href={url}>
                    <span>{title}</span>
                    <p>{desc}</p>
                  </CustomLink>
                </li>
              ))}
            </ul>
          </Chapters>
        </Section>
        <Section>
          <picture>
            <source media="(max-width:1200px)" srcSet={heatmapsMobile} />
            <img src={heatmaps} alt="Guides cover" title="Heatmaps" className="img-fluid" />
          </picture>
          <Chapters>
            <h2>Heatmaps</h2>
            <CustomLink href="/heatmaps/" className="arrow-paragraph" withArrow>
              Read the guide
            </CustomLink>
            <ul>
              {heatmapsData.map(({ desc, title, url }) => (
                <li key={url}>
                  <CustomLink href={url}>
                    <span>{title}</span>
                    <p>{desc}</p>
                  </CustomLink>
                </li>
              ))}
            </ul>
          </Chapters>
        </Section>
      </div>
      <StartUsing />
    </Layout>
  );
};

export default Guides;
